<template>
  <IconButton
    :title="props.title"
    :aria-label="props.title"
    :imgSrc="imgSrc"/>
</template>

<script setup lang="ts">
import IconButton from './IconButton.vue';
import imgSrc from "../../assets/edit-icon-48x48.png";

const props = defineProps<{
  title?: string
}>();
</script>

